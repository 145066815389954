<script setup lang="ts">
import { type FormFieldValues } from "@/components/organisms/Footer/Footer.interface";
import Footer from "@/components/organisms/Footer/Footer.vue";
import {
  getTranslatedProperty,
  getCategoryRoute,
} from "@shopware-pwa/helpers-next";
import { useNewsletter } from "@shopware-pwa/composables-next";
import { NewsletterFormState } from "@/types";
import { useStoreApi } from "@/composables/useStoreApi";
import type { Schemas } from "#shopware";
import { customFieldKeyResolver } from "@/constants/customFieldKeyResolver";

const { getNavigationMenu, getSeoUrls } = useStoreApi();
const navigationElements = await getNavigationMenu({
  activeId: "footer-navigation",
});
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const collectedLinkIds: string[] = [];
const linkItemReferences: Schemas["Category"][] = [];

navigationElements?.forEach((navigationElement) => {
  navigationElement.children.forEach((child) => {
    linkItemReferences.push(child);
    collectedLinkIds.push(child?.internalLink || child.id);
  });
});

const seoUrls = await getSeoUrls(collectedLinkIds);

if (Array.isArray(seoUrls?.elements)) {
  seoUrls?.elements.forEach((seoUrl) => {
    const linkItemReferenceIdx = linkItemReferences.findIndex(
      (item) => (item.internalLink || item.id) === seoUrl.foreignKey,
    );
    if (linkItemReferenceIdx === -1) return;
    linkItemReferences[linkItemReferenceIdx].seoUrls = [seoUrl];
  });
}

const links =
  navigationElements?.map((navigationElement, navigationElementIdx) => {
    const columnConvertedToNumber = parseInt(
      navigationElement?.customFields &&
        navigationElement?.customFields[
          customFieldKeyResolver["CATEGORY_SET_COLUMN"]
        ],
    );

    const columnToUse = isNaN(columnConvertedToNumber)
      ? navigationElementIdx + 1
      : columnConvertedToNumber;

    return {
      id: navigationElement.id,
      name: getTranslatedProperty(navigationElement, "name"),
      column: columnToUse,
      children: navigationElement.children.map((child) => {
        const seoUrl = child.seoUrls?.[0];
        const canCombineSeoUrl =
          seoUrl?.seoPathInfo && (seoUrl?.foreignKey || seoUrl?.id);
        const seoUrlLinkData = canCombineSeoUrl
          ? {
              path: seoUrl?.seoPathInfo.startsWith("/")
                ? seoUrl?.seoPathInfo
                : `/${seoUrl?.seoPathInfo}`,
              state: {
                routeName: "frontend.navigation.page",
                foreignKey: seoUrl?.foreignKey || seoUrl?.id,
              },
            }
          : null;

        return {
          id: child.id,
          name: getTranslatedProperty(child, "name"),
          link: formatLink(seoUrlLinkData || getCategoryRoute(child)),
        };
      }),
    };
  }) || [];

const { newsletterSubscribe } = useNewsletter();

const newsletterFormState = ref<NewsletterFormState>(
  NewsletterFormState.DEFAULT,
);

const onNewsletterFormSubmit = async (formValues: FormFieldValues) => {
  await newsletterSubscribe({
    email: formValues.email,
    option: "subscribe",
    firstName: formValues.firstName,
    lastName: formValues.lastName,
  });

  newsletterFormState.value = NewsletterFormState.SUBSCRIBED;
};
</script>

<template>
  <Footer
    :newsletter-form-state="newsletterFormState"
    :links="links"
    @newsletter-form-submit="onNewsletterFormSubmit"
  />
</template>
